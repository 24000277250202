import React from 'react'
import Async from 'react-async'

const loadUser = async ({signal}) => {
    const res = await fetch(`/api/ddw/user`, {signal})
    if (!res.ok) throw new Error(res.statusText)
    return res.json()
}

function DdwUser() {
    return (
        <Async promiseFn={loadUser}>
            <Async.Pending>Loading...</Async.Pending>
            <Async.Fulfilled>
                {data => (
                    <p>
                        Hello, {data.displayName}
                        (<a href={"/api/oauth/logout"} className={"App-link"}>logout</a>)
                    </p>
                )}
            </Async.Fulfilled>
            <Async.Rejected>
                {error => (
                    <a href={"/api/oauth"} className={"App-link"}>login</a>
                )}
            </Async.Rejected>
        </Async>
    )
}

export default DdwUser
