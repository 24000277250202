import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import logo from './logo.svg'
import './App.css'
import DdwUser from './DdwUser'
import graphy from 'graphy'
import Async from 'react-async'
import DownloadLink from "react-download-link"

function generateCatalog(org) {
    return new Promise(function(resolve) {
        let buffer = [],
            w = graphy.content.ttl.write({
                prefixes: {
                    '': `https://${org}.linked.data.world/d/ddw-catalogs/`,
                    dwec: 'https://dwec.data.world/v0/',
                    dcterms: 'http://purl.org/dc/terms/',
                    xsd: 'http://www.w3.org/2001/XMLSchema#',
                    dcat: 'http://www.w3.org/ns/dcat#'
                }
            })

        w.on('data', (statement) => {
            buffer.push(statement+'')
        })
        w.on('end', () => {
            resolve(buffer.join("\n"))
        })

        let now = new Date()
        w.write({
            type: 'c3',
            value: {
                ':MyFirstCatalog': {
                    a: 'dwec:Catalog',
                    'dcterms:identifier': '"MyFirstCatalog',
                    'dcterms:title': '"My First Catalog',
                    'dcterms:description': '"Example DWEC Catalog, generated by datacatalog.new',
                    'dcterms:issued': now,
                    'dcterms:modified': now
                }
            }
        })
        w.end()
    })
}

class CatalogCreateForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {org: ''}
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({org: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
    }


    render() {
        return (
            <form onSubmit={() => {}}>
                <p>
                <input type="text" value={this.state.org} onChange={this.handleChange} />
                </p>
                {(this.state.org !== "") &&
                        <Async promiseFn={() => generateCatalog(this.state.org)}>
                        <Async.Pending>Loading...</Async.Pending>
                        <Async.Fulfilled>
                        {data => (
                                <DownloadLink
                                    label={`Save ${this.state.org}-catalog.ttl`}
                                    filename={`${this.state.org}-catalog.ttl`}
                                    exportFile={() => data}
                                />
                        )}
                        </Async.Fulfilled>
                        <Async.Rejected>
                        {error => <b>{error + ''}</b>}
                        </Async.Rejected>
                        </Async>
                    }

            </form>
        )
    }
}

function App() {
    return (
        <Router>
        <div className="App">
            <Switch>
                <Route exact path="/">
                    <header className="App-header">
                        <img src={logo} className="App-logo" alt="logo"/>
                        <h1>data.world Starter App</h1>
                        <p>
                            Edit <code>src/App.js</code> and save to reload.
                        </p>
                        <DdwUser/>
                    </header>
                </Route>
                <Route exact path="/create-catalog">
                    <div>
                        <h3>Create a Data Catalog</h3>
                        <span>Enter the name of the data.world organization for which you wish to generate a catalog:</span>
                        <CatalogCreateForm/>
                    </div>
                </Route>
            </Switch>
        </div>
        </Router>
    )
}

export default App
